// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//NOTE TO DEVELOPER: Comment OUT these links when developing
export const environment = {
  production: false,
  apiUrl: "https://servcom.thewealthmarket.com/api/v1",
  usrUrl: "https://umgmt.thewealthmarket.com/api/v1",
  pcrUrl: "https://stash.thewealthmarket.com/api/v1",
  miscUrl: "https://ops.thewealthmarket.com/api/v1",
  adrsUrl: "https://add.thewealthmarket.com/api/v1",
  mAcctUrl: "https://exchange.thewealthmarket.com/api/v1",
  paystackURL: "https://api.paystack.co",
  worldTimeUrl: "http://worldtimeapi.org/api/ip",
  notificationUrl: "https://chime.thewealthmarket.com/api/v1"
};
