// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//NOTE TO DEVELOPER: Comment OUT these links when developing
export const environment = {
  production: false,
  apiUrl: "https://servcom.thewealthmarket.com/api/v1",
  usrUrl: "https://umgmt.thewealthmarket.com/api/v1",
  pcrUrl: "https://stash.thewealthmarket.com/api/v1",
  miscUrl: "https://ops.thewealthmarket.com/api/v1",
  adrsUrl: "https://add.thewealthmarket.com/api/v1",
  mAcctUrl: "https://exchange.thewealthmarket.com/api/v1",
  paystackURL: "https://api.paystack.co",
  worldTimeUrl: "http://worldtimeapi.org/api/ip",
  notificationUrl: "https://chime.thewealthmarket.com/api/v1"

};

//NOTE TO DEVELOPER: Uncomment these links when developing
// export const environment = {
//   production: false,
//   apiUrl: "https://service.thewealthmarket.com/api/v1",
//   usrUrl: "https://users.thewealthmarket.com/api/v1",
//   pcrUrl: "https://pcr.thewealthmarket.com/api/v1",
//   miscUrl: "https://wmapi.thewealthmarket.com/api/v1",
//   adrsUrl: "https://add.thewealthmarket.com/api/v1",
//   mAcctUrl: "https://ma.thewealthmarket.com/api/v1",
//   paystackURL: "https://api.paystack.co",
//   worldTimeUrl: "http://worldtimeapi.org/api/ip",
//   notificationUrl: "https://na.thewealthmarket.com/api/v1"

// };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

/* old core api details:
password": "SeunCarta$156",
usernameEmail": "seuncarta@gmail.com"

password": "Create1acct&com",
usernameEmail": "omoneferuemu@gmail.com"

password: Create1acct&com
usernameEmail: ruemuomonefe@gmail.com

email: michaelajiri@gmail.com
password: Demos123#

password: Aanuh@1199
email: olaanuoluwapo123@gmail.com

Client ID:
3c38d033-4666-31ca-831b-7113b38fcfe9
Client Key:
b55163c7f417945dba7dc35d1e8dfe8ce6370df548899a6c226982fd24cb1ec4
apiUrl: "https://app.thewealthmarket.com/api/v1"
*/
